// Modules
import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

// Commons
import { Select } from '../Select/styles';
import { FlexColumn, Button } from '../../theme/styles';
import { Input } from '../Input/styles';
import { Label } from '../Label/styles';
import { ModalInputsContainer } from '../ModalInputsContainer/styles';
import { ModalInputsRowContainer } from '../ModalInputsRowContainer/styles';

import { ButtonsContainer, Error } from './styles';

// Validation
const validation = Yup.object().shape({
  name: Yup.string().required('Required'),
  account: Yup.string().required('Required'),
  subaccount: Yup.string().nullable(),
  role: Yup.string().required('Required'),
  email: Yup.string()
    .email()
    .required('Required'),
});

const roles = [
  { name: 'admin', value: 'ADMIN' },
  { name: 'client', value: 'CLIENT' },
];

class UsersForm extends React.Component {
  componentDidMount() {
    const { fetchAccounts } = this.props;
    fetchAccounts();
  }

  render() {
    const {
      deleteModal,
      cleanErrors,
      createUser,
      accounts,
      fetchAccountClients,
      accountClients,
      me,
      error,
      isSubmitting,
    } = this.props;

    if (accountClients.length > 0) {
      accountClients.unshift({});
    }

    return (
      <Formik
        initialValues={{
          name: '',
          email: '',
          account: accounts[0]
            ? {
                id: accounts[0].id,
                type: accounts[0].type,
              }
            : null,
          subaccount: accountClients[0] ? accountClients[0].id : null,
          role: roles[0].value,
        }}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          const newUser = {
            name: values.name,
            email: values.email,
            firebaseUid: me.firebaseUid,
            account: values.account.id,
            role: values.role,
          };
          if (values.subaccount) {
            newUser.subaccount = values.subaccount;
          }
          createUser(newUser);
          setSubmitting(false);
          cleanErrors();
        }}
      >
        {formProps => {
          const {
            values,
            touched,
            errors,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
          } = formProps;

          return (
            <>
              <ModalInputsContainer paddingBottom={20}>
                <ModalInputsRowContainer rowDirection>
                  <FlexColumn>
                    <Label htmlFor="name" style={{ display: 'block' }}>
                      Nombre
                    </Label>
                    <Input
                      marginRight
                      id="name"
                      placeholder="Nombre"
                      type="text"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.name && touched.name
                          ? 'text-input error'
                          : 'text-input'
                      }
                    />
                    {errors.name && touched.name && (
                      <Error withoutPadding>{errors.name}</Error>
                    )}
                  </FlexColumn>
                  <FlexColumn>
                    <Label htmlFor="email" style={{ display: 'block' }}>
                      Email
                    </Label>
                    <Input
                      id="email"
                      placeholder="Email"
                      type="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.email && touched.email
                          ? 'text-input error'
                          : 'text-input'
                      }
                    />
                    {errors.email && touched.email && (
                      <Error withoutPadding>{errors.email}</Error>
                    )}
                  </FlexColumn>
                </ModalInputsRowContainer>

                <ModalInputsRowContainer rowDirection>
                  <FlexColumn>
                    <Label htmlFor="account" style={{ display: 'block' }}>
                      Cuenta
                    </Label>
                    <Select
                      width={250}
                      marginRight
                      id="account"
                      onChange={e => {
                        const selectedAccount = accounts.find(
                          account => account.id === e.target.value
                        );
                        setFieldValue('account.id', selectedAccount.id);
                        setFieldValue('account.type', selectedAccount.type);
                        fetchAccountClients({ id: e.target.value });
                      }}
                    >
                      {accounts.map(account => (
                        <option key={account.value} value={account.id}>
                          {account.name}
                        </option>
                      ))}
                    </Select>
                    {errors.account && touched.account && (
                      <Error>{errors.account}</Error>
                    )}
                  </FlexColumn>
                  {values.account && values.account.type === 'PARTNER' ? (
                    <FlexColumn>
                      <Label htmlFor="subaccount" style={{ display: 'block' }}>
                        Sub-cuenta
                      </Label>
                      <Select
                        width={250}
                        id="subaccount"
                        onChange={handleChange}
                      >
                        {accountClients.map(accountClient => (
                          <option
                            key={accountClient.id}
                            value={accountClient.id}
                          >
                            {accountClient.name}
                          </option>
                        ))}
                      </Select>
                      {errors.subaccount && touched.subaccount && (
                        <Error withoutPadding>{errors.subaccount}</Error>
                      )}
                    </FlexColumn>
                  ) : null}
                </ModalInputsRowContainer>

                <ModalInputsRowContainer>
                  <FlexColumn>
                    <Label htmlFor="role" style={{ display: 'block' }}>
                      Role
                    </Label>
                    <Select width={250} id="role" onChange={handleChange}>
                      {roles.map(role => (
                        <option key={role.value} value={role.value}>
                          {role.name}
                        </option>
                      ))}
                    </Select>
                    {errors.role && touched.role && (
                      <Error>{errors.role}</Error>
                    )}
                  </FlexColumn>
                </ModalInputsRowContainer>
                {error && <Error>{error}</Error>}
              </ModalInputsContainer>
              <ButtonsContainer>
                <Button
                  marginBottom="15"
                  marginRight="15"
                  type="button"
                  disabled={isSubmitting}
                  onClick={() => {
                    deleteModal();
                    cleanErrors();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  primary
                  marginBottom="15"
                  marginRight="15"
                  type="button"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  Crear Usuario
                </Button>
              </ButtonsContainer>
            </>
          );
        }}
      </Formik>
    );
  }
}

UsersForm.propTypes = {
  createUser: PropTypes.func.isRequired,
  values: PropTypes.shape({}),
  touched: PropTypes.shape({}),
  errors: PropTypes.shape({}),
  deleteModal: PropTypes.func.isRequired,
  cleanErrors: PropTypes.func.isRequired,
  fetchAccounts: PropTypes.func.isRequired,
  fetchAccountClients: PropTypes.func.isRequired,
  accounts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  accountClients: PropTypes.arrayOf(PropTypes.shape({})),
  me: PropTypes.shape({}).isRequired,
  error: PropTypes.string,
};

UsersForm.defaultProps = {
  values: {},
  touched: {},
  errors: {},
  error: null,
  accountClients: [],
};

export default UsersForm;
